<template>
  <v-card class="elevation-5 mx-auto mt-15 rounded-lg" :loading="loading" width="450">
    <v-card-title class="title primary--text"> Setting </v-card-title>
    <v-card-text class="pt-10">
      <div class="row">
        <div class="col-8">
          <h3>Email Notification</h3>
          <h5>Get email find out what's going on when
            you're not online. you can turn these off.
          </h5>
        </div>
        <div class="col-2">
        </div>
        <div class="col-2 right">
            <v-switch inset top class="mt-0" v-model="emailNotification" @change="updateSettings" ></v-switch>
        </div>

      </div>

    </v-card-text>
    <v-snackbar v-model="success" :timeout="3000" color="green accent-4" align-baseline="center">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="success = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="error" :timeout="3000" color="red accent-4" align-baseline="center">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="error = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  data: function () {
    return {
      loading: false,
      emailNotification : true,
      
      errors: {},
      message: "",
      snackbar: false,
      token: null,
      
      success: false,
      error: false,
    };
  },
  methods: {
    updateSettings(){
      this.$axios
        .put("/upadtesettings", {emailNotification: this.emailNotification}).then((response) => {
          let data = response.data;
          if (data.success) {
            data;
          }
        });
    },
    getUser(){
      this.$axios
        .get("employee/" + this.user.id)
        .then((response) => {
          let data = response.data;
          if (data.success) {
            this.emailNotification = data.employee.email_notification
          }
        });
    }

  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.getUser();
  },
};
</script>

<style scoped lang="scss">
.container {
  position: relative;
}

.edit-icon {
  position: absolute;
  left: 205px;
  top: 70px;
  background-color: black;
  border-radius: 50%;
  padding: 2px;
  height: 25px;
  width: 25px;
  border: 3px solid white;
  padding: 0px !important;
}

.imagePreviewWrapper {
  width: 80px;
  height: 80px;
  display: block;
  cursor: pointer;
  margin: 0 auto 30px;
  background-size: cover;
  background-position: center center;
}

.title {
  background-color: rgba(25, 118, 210, 0.1) !important;
  justify-content: center;
}
</style>